<template>
  <div>
  <svg class="lil-plane" :class="{'lil-plane--active': planeActive}" :style="[planeActive ? planeOpts : {}]" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512">
    <g>
      <path class="lil-plane__secondary" d="M352,232l104,80v80h-72l-48-40L352,232z"/>
      <path class="lil-plane__secondary" d="M160,232L56,312v80h64l56-32L160,232z"/>
      <path class="lil-plane__white" d="M272,344l16-208l64,48v168l-48,36L272,344z"/>
      <path class="lil-plane__white" d="M240,344l-16-208l-64,48v168l48,36L240,344z"/>
      <path class="lil-plane__secondary" d="M288,136h-64V80c0-16,32-56,32-56s32,40,32,56V136z"/>
      <path class="lil-plane__secondary" d="M224,129.75h64V344h-64V129.75z"/>
      <path class="lil-plane__white" d="M272,344h-32l-16,96v16l32,32l32-32v-16L272,344z"/>
      <path class="lil-plane__secondary" d="M208,384v56l-5.04,6.72l-13.76,18.4L184,472l-10.56-2.08L104,456l-8-40l64-64L208,384z"/>
      <path class="lil-plane__black" d="M248,160h16v16h-16V160z"/>
      <path class="lil-plane__black" d="M248,192h16v16h-16V192z"/>
      <path class="lil-plane__black" d="M248,224h16v16h-16V224z"/>
      <path class="lil-plane__white" d="M224,104h64v32h-64V104z"/>
      <path class="lil-plane__primary" d="M224,136l-64,48v40l64-48V136z"/>
      <path class="lil-plane__primary" d="M184,368l-80,88l-8-40l64-64L184,368z"/>
      <path class="lil-plane__white" d="M189.2,465.12L184,472l-10.56-2.08l-20.32-33.84l13.76-8.16L189.2,465.12z"/>
      <path class="lil-plane__white" d="M208,426.64V440l-5.04,6.72L177.6,412.8l12.8-9.6L208,426.64z"/>
      <path class="lil-plane__white" d="M24,296h32v96H24V296z"/>
      <path class="lil-plane__primary" d="M160,232L56,312v40l104-80V232z"/>
      <path class="lil-plane__secondary" d="M416,416l-8,40l-69.36,13.92L328,472l-5.2-6.88l-13.84-18.48L304,440v-56l48-32L416,416z"/>
      <path class="lil-plane__primary" d="M288,136l64,48v40l-64-48V136z"/>
      <path class="lil-plane__primary" d="M328,368l80,88l8-40l-64-64L328,368z"/>
      <path class="lil-plane__white" d="M358.88,436.08l-20.24,33.84L328,472l-5.2-6.88l22.32-37.2L358.88,436.08z"/>
      <path class="lil-plane__white" d="M334.4,412.8l-25.44,33.84L304,440v-13.36l17.6-23.439L334.4,412.8z"/>
      <path class="lil-plane__white" d="M488,392h-32v-96h32V392z"/>
      <path class="lil-plane__primary" d="M352,232l104,80v40l-104-80V232z"/>
      <path class="lil-plane__primary" d="M224,288l-32,24v-64l32-24V288z"/>
      <path class="lil-plane__primary" d="M288,288l32,24v-64l-32-24V288z"/>
      <path class="lil-plane__primary" d="M208,344h32v96h-32V344z"/>
      <path class="lil-plane__primary" d="M272,344h32v96h-32V344z"/>
    </g>
  </svg>
    <div class="wave" :class="{'wave--active': waveActive}" :style="[waveActive ? waveOpts : {}]">
      <div class="wave__visual"></div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { gsap } from 'gsap'

  export default {
    data () {
      return {
        cursor: this.$cursor,
        eggSpeed: 200,
        eggDelay: 1,
        animTimer: null,
        waveDuration: 1,
        waveActive: false,
        planeActive: false,
        planeAnim: null,
        planeSpeed: 10,
        waveOpts: {
          left: 0,
          top: 0,
          transform: 'none'
        },
        planeOpts: {
          left: 0,
          top: 0,
          transform: 'none'
        }
      }
    },
    computed: {
      ...mapState({
        eggPlane: state => state.eggs.plane
      }),
      speed () {
        let s = 0
        if (this.cursor) {
          s = this.cursor.dist
        }
        return s
      }
    },
    methods: {
      ...mapActions({
        findEgg: 'findEgg'
      }),
      waveGoesBoom () {
        this.waveOpts.left = this.cursor.posX + 'px'
        this.waveOpts.top = this.cursor.posY + 'px'
        this.waveOpts.transform = 'rotateZ(' + (this.cursor.deg) + 'deg)'
        this.waveActive = true
      },
      planeGoesFew () {
        this.planeOpts.left = this.cursor.posX + 'px'
        this.planeOpts.top = this.cursor.posY + 'px'
        this.planeOpts.transform = 'rotateZ(' + (this.cursor.deg - 90) + 'deg)'
        this.$nextTick(() => {
          this.planeAnim = gsap.to(this.planeOpts, {
            ease: 'none',
            duration: this.waveDuration,
            left: (this.cursor.dist * this.cursor.normalX * this.planeSpeed + this.cursor.posX) + 'px',
            top: this.cursor.dist * this.cursor.normalY * this.planeSpeed + this.cursor.posY + 'px'
          })
        })
        this.planeActive = true
      }
    },
    watch: {
      speed () {
        if (this.speed >= this.eggSpeed) {
          if (this.animTimer === null) {
            this.waveGoesBoom()
            this.planeGoesFew()
            this.animTimer = setTimeout(() => {
              this.waveActive = false
              this.planeActive = false
              this.animTimer = null
              if (!this.eggPlane.found) {
                this.findEgg('plane')
              }
            }, this.waveDuration * 1000)
          }
        }
      }
    }
  }
</script>
