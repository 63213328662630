<template>
  <transition-group name="fade" id="app" class="app" :class="appClasses" tag="div">
    <div v-if="!checkDone && !isIE" class="main" data-flex="justify-center items-center" key="0">
      <div class="container container--small">
        <div class="container__inner">
          <div data-space="px5">
            <p data-font="huge primary semi">Hello, and welcome to my website !<br>To get started, click the button below.</p>
            <button class="btn btn--big"
                    data-space="mt5"
                    @click="startXp">
              <span class="btn__text">button below</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="checkDone && !isMobile && wideEnough" class="app__inner" key="1">
      <div class="main" data-flex="items-center">
        <Grid></Grid>
        <div class="page"
             :class="{ 'page--shrinked': sidebarOpened }"
             data-flex="justify-center items-center">
          <WAYH></WAYH>
          <Logo></Logo>
          <Code></Code>
          <Simon></Simon>
          <div class="toolbar">
            <ThemePicker></ThemePicker>
            <Saver></Saver>
            <VolumePicker></VolumePicker>
          </div>
          <noob-menu></noob-menu>
        </div>
        <Sidebar></Sidebar>
      </div>
      <LilPlane></LilPlane>
      <Console></Console>
      <Overlay></Overlay>
      <Dogg></Dogg>
    </div>
    <div v-else class="main" data-flex="justify-center items-center" key="2">
      <div class="container container--small">
        <div class="container__inner">
          <p data-font="huge primary bold" data-space="px5">Dayuuum, I'm sorry mate !</p>
          <p v-if="isMobile" data-font="big white semi" data-space="px5">You seem to be using a touch device, and this experience is pretty much meaningless on those.<br>You should really come back on your desktop/laptop !</p>
          <p v-else-if="isIE" data-space="px5">Internet Explorer is too old to handle the power of the experience you're about to live, do you mind coming back using another browser ?</p>
          <p v-else data-font="big white semi" data-space="px5">Your window might be a bit too small for you to enjoy this experience properly.<br>Just enlarge it and things will work out !</p>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import keys from './mixins/keys'
  import ThemePicker from './components/ThemePicker'
  import Sidebar from './components/Sidebar'
  import Overlay from './components/Overlay'
  import Dogg from './components/Dogg'
  import WAYH from './components/WAYH'
  import Code from './components/Code'
  import LilPlane from './components/LilPlane'
  import Logo from './components/Logo'
  import Console from './components/Console'
  import Grid from './components/Grid'
  import Simon from './components/Simon'
  import VolumePicker from './components/VolumePicker'
  import Saver from './components/Saver'
  import NoobMenu from '@/components/NoobMenu'

  export default {
    mixins: [ keys ],
    components: {
      NoobMenu,
      ThemePicker,
      Sidebar,
      Overlay,
      Dogg,
      WAYH,
      Code,
      LilPlane,
      Logo,
      Console,
      Grid,
      Simon,
      VolumePicker,
      Saver
    },
    data () {
      return {
        isMobile: null,
        checkDone: false,
        wideEnough: true,
        isIE: false
      }
    },
    computed: {
      ...mapState({
        appClasses: state => state.appClasses,
        sidebarOpened: state => state.sidebarOpened
      })
    },
    created () {
      if (window.document.documentMode) {
        this.isIE = true
      }
      document.addEventListener('touchstart', this.isTouch, {once: true})
      document.addEventListener('mousemove', this.isMouse, {once: true})
      window.addEventListener('resize', this.checkSize)
      this.checkSize()
    },
    methods: {
      ...mapActions({
        findEgg: 'findEgg'
      }),
      isTouch () {
        document.removeEventListener('mousemove', this.isMouse)
        this.isMobile = true
      },
      isMouse () {
        document.removeEventListener('touchstart', this.isTouch)
        this.isMobile = false
      },
      checkSize () {
        this.wideEnough = window.matchMedia("only screen and (min-width: 880px)").matches
      },
      startXp () {
        this.checkDone = true
      }
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
  @import "assets/styles/index";
</style>
